import React from 'react'
import classnames from 'classnames'
import style from './heading.module.scss'

interface Props {
  children: React.ReactNode
  className?: string
}

const Heading = ({ children, className }: Props): JSX.Element => (
  <h2 className={classnames(style.heading, className)}>{children}</h2>
)

export default Heading
