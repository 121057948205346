import { useEffect } from 'react'
import getConfig from 'next/config'
import { NextPageContext } from 'next'
const {
  publicRuntimeConfig: { categoriesID, defaultCategoryBanner, production, baseDomain, baseURL },
} = getConfig()
import CategoryType from 'store/types/ts/category'
import ArticleType from 'store/types/ts/article'
import {
  CategoriesLayout,
  DefaultLayout,
  EmpiresLayout,
  FrontpageTopLayout,
} from 'components/layout/listing/layouts'
import { withDispatch } from 'store'
import { getCategories } from 'store/category'
import Container from '../components/container'
import Heading from '../components/heading'

interface Props {
  articles: ArticleType[]
  empires: ArticleType[]
  categories: CategoryType[]
}

interface Hero {
  image: string
  heading: string
  subHeading: string
  align: string
}

interface Context extends NextPageContext {
  setHero: ({ image, heading, subHeading }: Hero) => void
  fetchFrontPageArticles: () => ArticleType[]
  fetchCategoryArticlePage: ({ category }: { category: string }) => ArticleType[]
}

const Home = ({ articles, empires, categories }: Props): JSX.Element => {
  const renderCategories = () =>
    Object.values(categories).map((category) => (
      <CategoriesLayout key={category.id} item={category} />
    ))

  const renderEmpires = () => {
    return empires.map((empire) => <EmpiresLayout key={empire.id} id={empire.id} item={empire} />)
  }

  const renderTop = () => {
    const firstThreeArticles = articles.slice(0, 3)
    return <FrontpageTopLayout articles={firstThreeArticles} />
  }

  const renderArticles = () => {
    return articles
      .slice(3, 9)
      .map((article) => <DefaultLayout key={article.id} id={article.id} item={article} />)
  }

  useEffect(() => {
    if (production && window && window.location.hostname !== baseDomain) {
      window.location.replace(baseURL)
    }
  })

  return (
    <>
      <Container>
        {articles && (
          <section className="pt-3 pb-3 pb-lg-5">
            <Heading>Latest Stories</Heading>
            <div className="row mb-md-5">{renderTop()}</div>
            <div className="row">{renderArticles()}</div>
          </section>
        )}
        {empires && (
          <section className="pt-4 pb-3 pb-lg-5">
            <Heading>Empires of New Eden</Heading>
            <div className="row row-offset">{renderEmpires()}</div>
          </section>
        )}
        {categories && (
          <section className="pt-4 pb-3 pb-5">
            <Heading>Categories</Heading>
            <div className="row">{renderCategories()}</div>
          </section>
        )}
      </Container>
    </>
  )
}

Home.getInitialProps = async (context: Context) => {
  const { setHero, fetchFrontPageArticles, fetchCategoryArticlePage, store } = context

  const categories = getCategories(store)

  const [[, articles], [, empires]] = await Promise.all([
    fetchFrontPageArticles(),
    fetchCategoryArticlePage({ category: categoriesID.empires }),
  ])

  // await FrontpageListing.getInitialProps({ store, id: categoryEmpires.id })

  // console.log('store', store.getState())

  setHero({
    image: defaultCategoryBanner,
    heading: 'Universe of EVE',
    subHeading: 'Explore New Eden through the Lore of EVE',
    align: 'center',
  })

  return {
    articles,
    empires,
    categories,
  }
}

export default withDispatch({
  hero: ['setHero'],
  article: ['fetchFrontPageArticles'],
  category: ['fetchCategoryArticlePage'],
  shared: ['fetchMissing'],
})(Home)
